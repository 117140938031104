<template>
  <b-container>
		<b-row class="mt-2">
      <b-col>
        <h4>本臨床研究のご協力へのご同意ありがとうございました。</h4>
      </b-col>
		</b-row>
		<b-row v-if="consentNo">
      <b-col>
        <b-card no-body class="my-3 w-80">
          <b-list-group>
            <b-list-group-item>
              同意番号: <span class="h4 font-weight-bold text-info">{{ consentNo }}</span>
            </b-list-group-item>
            <b-list-group-item>
              送信時刻: <span class="text-info">{{ timestamp }}</span>
            </b-list-group-item>
          </b-list-group>
        </b-card>
        <div>※同様の内容をメールで送信しました。メールが届いていることをご確認ください。</div>
      </b-col>
    </b-row>
		<b-row class="mt-5">
      <b-col>
        <b-btn block size="lg" variant="success"
          :href="googleFormUrl">
          <strong>続いてこのボタンを押して、開始前アンケートの回答をお願いします。</strong>
        </b-btn>
      </b-col>
    </b-row>
	</b-container>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'Form',
  data () {
    return {
      googleFormUrl: process.env.VUE_APP_GOOGLE_FORM_URL_ENQUETE
    }
  },
  computed: {
    consentNo () {
      return this.$store.state.consented && this.$store.state.consented.consentNo
    },
    timestamp () {
      const t = this.$store.state.consented && this.$store.state.consented.createdAt
      return t && moment(t, 'x').tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')
    }
  },
  methods: {
  }
}
</script>
